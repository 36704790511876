<template>
    <div class="container bg-white rounded mt-5 p-4" style="width: 768px">
        <h2 class="fs-20">Voucher č. 70000000</h2>

        <h3 class="fs-18 mt-4">Dentální hygiena bez Airflow</h3>
        <span class="text-gray fs-14">Platnost do 31. 1. 2021.</span>

        <div class="row">
            <div class="col-12 col-md-6">
                <h3 class="fs-18 mt-4">V ceně</h3>

                <ul class="voucher-list">
                    <li>Odstranění nánosu ručními nástroji a ultrazvukem</li>
                    <li>Odstranění nánosu ručními nástroji a ultrazvukem</li>
                    <li>Odstranění nánosu ručními nástroji a ultrazvukem</li>
                    <li>Odstranění nánosu ručními nástroji a ultrazvukem</li>
                    <li>Odstranění nánosu ručními nástroji a ultrazvukem</li>
                </ul>
            </div>
            <div class="col-12 col-md-auto ml-auto">
                <div
                    class="voucher-preview rounded"
                    style="
                        background-image: url('https://pilulkacz.vshcdn.net/zoh4eiLi/IMG/86400/ZXyQUE62vU5mZsNfUHA09fosAB6c1BonOQ01w43_dK4/trim:0:ffffff/watermark:.1/watermark_url:aHR0cHM6Ly93d3cuaXBpbHVsa2EuY3ovc3lzdGVtLWZpbGVzL3dhdGVybWFyay9jei5wbmc/aHR0cHM6Ly93d3cucGlsdWxrYS5jei9hc3NldHMvaW1hZ2UvMjAyMS0wNy8xNjI2MjUyMzg3LXNodXR0ZXJzdG9jay0xNDk2MDEwODUxLmpwZz92PTE2MjYyNTIzODc.jpg');
                    "
                ></div>

                <button class="btn btn-primary mt-2">Sdílet / Tisknout voucher ></button>
            </div>
        </div>

        <div class="bg-grey-light p-3 text-uppercase border-bottom d-flex fs-14 fwb mt-5">
            <strong>Kód voucheru</strong>
            <strong class="ml-auto">7000000</strong>
        </div>

        <div class="row fs-14">
            <div class="col pt-3 text-dark">Kde uplatnit</div>
            <div class="col ml-auto">
                <a href="/" class="text-dark text-decoration-underline d-block mt-3">
                    <strong>Dentanela Clinic</strong> - Lovosická 440/40, 196 00, Praha 9
                </a>

                <a href="/" class="text-dark text-decoration-underline d-block mt-2">
                    <strong>Smile Dental clinic</strong> - Lovosická 440/40, 196 00, Praha 9
                </a>

                <a href="/" class="text-primary text-decoration-underline d-block mt-2 fwb">
                    Všechny pobočky na kterých lze službu uplatnit >
                </a>
            </div>

            <h4 class="fs-18">Jak uplatnit</h4>

            <hr />

            <p class="text-gray">
                <strong class="text-dark">Online termíny:</strong>
                Zakoupením voucheru je automaticky vytvořena závazná rezervace termínu, ordinaci již není třeba dále kontaktovat.
            </p>

            <p class="text-gray">
                <strong class="text-dark">Otevřené vouchery:</strong>
                Termín si domluvte předem na tel. 604 418 519. V případě dotazů k objednání volejte nebo pište SMS na tel. 604 418 519.
            </p>
        </div>

        <h3 class="fs-20 mt-4">Nejbližší pobočka</h3>

        <div class="bg-grey-light px-3 py-4 row rounded mt-3">
            <div class="col-12 col-md-4 text-dark fs-13">
                <h4 class="fs-18">Dentanela clinic</h4>

                <p>Lovosická 440/40, 190 00 Praha 9</p>

                <p class="my-2">E-mail: <a href="mailto:info@dentanela.cz" class="text-dark">info@dentanela.cz</a></p>

                <p class="my-2">Tel.: <a href="tel:+420774809908" class="text-dark">+420 774 809 908</a></p>

                <p class="my-2">
                    <a href="//www.dentanela.cz">www.dentanela.cz</a>
                </p>
            </div>

            <div class="col-12 col-md-4">
                <MiniMap :lat="50" :lng="14" map-class="w-100"></MiniMap>
            </div>

            <div class="col-12 col-md-4 d-flex flex-column">
                <div class="d-flex align-items-center justify-content-end">
                    <i class="ico ico--star-active"></i>
                    <strong class="ml-2 fs-13">{{ $numberFormat(4.6) }} z 5</strong>
                </div>

                <button class="btn btn-outline-primary mt-auto fs-13 w-75 btn-sm ml-auto">Detail pobočky</button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import MiniMap from '~/components/objects/MiniMap.vue'

export default {
    components: { MiniMap },
}
</script>

<style lang="scss" scoped>
.voucher-preview {
    width: 222px;
    height: 222px;
    background-size: cover;
    background-position: center;
}

.voucher-list {
    list-style-type: none;
    padding-left: 1rem;

    li {
        color: $color-dark;
        line-height: 2rem;
        font-size: 14px;
        position: relative;

        &::before {
            position: absolute;
            top: 13.5px;
            left: -14px;
            width: 5px;
            height: 5px;
            border-radius: 5px;
            content: '';
            background: $color-cta;
        }
    }
}
</style>
