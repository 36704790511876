import { RuntimeConfig } from '@nuxt/schema'
import { FetchError } from 'ofetch'
import NotFoundError from '../app/error/NotFoundError'
import ViewProductResponse from '~/models/Api/ViewProductResponse'
import ProductAddonSaleAreasResponsePart from '~/models/Api/ProductAddonSaleAreasResponsePart'
import ProductSimilarProductsResponse from '~/models/Api/ProductSimilarProductsResponse'
import ProductRatingResponse from '~/models/Api/ProductRatingResponse'
import SelectedDiscussionList from '~/models/Api/SelectedDiscussionList'
import DiscussionGetWatchdog from '~/models/Api/DiscussionGetWatchdog'
import LikePost from '~/models/Discussion/LikePost'
import DisLikePost from '~/models/Discussion/DisLikePost'
import FavoriteInsertOrDeleteResponse from '~/models/Api/FavoriteInsertOrDeleteResponse'
import Review from '~/models/ReviewClub/Review'
import ProductAddonSaleBundleResponse from '~/models/Api/ProductAddonSaleBundleResponse'
import GiftEventProductsResponse from '~/models/Api/GiftEventProductsResponse'
import ReportRequestBody from '~/models/Discussion/ReportRequestBody'
import ProductFrequentlyActualizedData from '~/models/Api/ProductFrequentlyActualizedData'
import ProductFrequentlyActualizedDataCollection from '~/models/Api/ProductFrequentlyActualizedDataCollection'
import CommentAggregateResponse from '~/models/Api/CommentAggregateResponse'
// import { useSentry } from '~/plugins/sentry'
import { AddonSaleBundleStrategy } from '~/models/Product/AddonSaleBundleStrategy'
import ViewCounsellingDetailResponse from '~/models/Api/Counselling/ViewCounsellingDetailResponse'
import ViewCounsellingHomepageResponse from '~/models/Api/Counselling/ViewCounsellingHomepageResponse'
import ViewCounsellingListResponse from '~/models/Api/Counselling/ViewCounsellingListResponse'
import ViewCounsellingListRequest from '~/models/Api/Counselling/ViewCounsellingListRequest'
import UpsertCounsellingPostRequest from '~/models/Api/Counselling/UpsertCounsellingPostRequest'
import UpsertCounsellingPostResponse from '~/models/Api/Counselling/UpsertCounsellingPostResponse'
import BasketProductCarouselsResponse from '~/models/Api/BasketProductCarouselsResponse'
import AddBasketVoucherSuccessResponse from '~/models/Api/AddBasketVoucherSuccessResponse'
import AddBasketVoucherErrorResponse from '~/models/Api/AddBasketVoucherErrorResponse'
import ViewBasketResponse from '~/models/Api/ViewBasketResponse'
import ViewPharmacyListRequest from '~/models/Api/Pharmacy/ViewPharmacyListRequest'
import ViewPharmacyListResponse from '~/models/Api/Pharmacy/ViewPharmacyListResponse'
import GeneralResponse from '~/models/Api/GeneralResponse'
import ViewPharmacyDetailResponse from '~/models/Api/Pharmacy/ViewPharmacyDetailResponse'
import ViewPharmacyProductsAvailabilityResponse from '~/models/Api/Pharmacy/ViewPharmacyProductsAvailabilityResponse'
import ComplaintHomepageResponse from '~/models/Api/Complaints/ComplaintHomepageResponse'
import ComplaintDetailResponse from '~/models/Api/Complaints/ComplaintDetailResponse'
import ComplaintUpsertResponse from '~/models/Api/Complaints/ComplaintUpsertResponse'
import OrdersForComplaintResponse from '~/models/Api/Complaints/OrdersForComplaintResponse'
import UpsertComplaintRequest from '~/models/Complaint/UpsertComplaintRequest'
import ComplaintFindOrderResponse from '~/models/Api/Complaints/ComplaintFindOrderResponse'
import SlotsResponse from '~/models/Api/SlotsResponse'
import ComplaintSettingsResponse from '~/models/Api/Complaints/ComplaintSettingsResponse'
import ViewStaticContentResponse from '~/models/Api/ViewStaticContentResponse'
import ViewArticleListResponse from '~/models/Api/Article/ViewArticleListResponse'
import PopUpRegistrationResponse from '~/models/Api/PopUpRegistrationResponse'

export default class Repository {
    private config: RuntimeConfig

    constructor(config: RuntimeConfig) {
        this.config = config
    }

    constructUrl(url: string): string {
        if (process.client) {
            return `${this.config.public.apiUrl}${url}`
        } else {
            return `${this.config.public.apiUrlInsideDocker}${url}`
        }
    }

    async getCustomAddonSaleCarousels() {
        const url = this.constructUrl(`/api/v2/product/custom-addon-sale-carousels`)
        const response = await fetch(url)

        if (response.status === 200) {
            return (await response.json()).response
        } else {
            throw new Error('Addon salses data could not be loaded.')
        }
    }

    async getProductAddonSaleAreas(productId: number): Promise<ProductAddonSaleAreasResponsePart[]> {
        const url = this.constructUrl(`/api/v2/product/addon-sale-areas?productId=${productId}`)
        const response = await fetch(url)

        if (response.status === 200) {
            return (await response.json()).response
        } else {
            throw new Error('Addon salses data could not be loaded.')
        }
    }

    async getProductFrequentlyActualizedData(productIds: number[]): Promise<ProductFrequentlyActualizedDataCollection> {
        const headers = {
            platform: 'pwa',
        }
        const response = await this.getData(
            `/api/v2/product/frequently-actualized-data`,
            {
                ids: productIds,
            },
            headers
        )
        const dataAsArray: ProductFrequentlyActualizedData[] = response.products

        const collection: ProductFrequentlyActualizedDataCollection = {}
        dataAsArray.forEach((data) => {
            collection[data.id] = data
        })

        return collection
    }

    async getProductSimilarProducts(productId: number): Promise<ProductSimilarProductsResponse> {
        const url = this.constructUrl(`/api/v2/product/similar?productId=${productId}`)
        const response = await fetch(url)

        if (response.status === 200) {
            return (await response.json()).response
        } else {
            throw new Error('Similar products data could not be loaded.')
        }
    }

    async getCounsellingDetail(counsellingPostId: number): Promise<ViewCounsellingDetailResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            `/api/v2/counselling/detail`,
            {
                id: counsellingPostId,
            },
            headers
        )
    }

    async getCounsellingHomepage(): Promise<ViewCounsellingHomepageResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(`/api/v2/counselling/homepage`, {}, headers)
    }

    async getCounsellingList(request: ViewCounsellingListRequest): Promise<ViewCounsellingListResponse> {
        return await this.getData('/api/v2/counselling/list', request)
    }

    async upsertCounsellingPost(request: UpsertCounsellingPostRequest): Promise<UpsertCounsellingPostResponse> {
        return await this.getData('/api/v2/counselling/upsert', request)
    }

    async getPharmacyList(request: ViewPharmacyListRequest): Promise<GeneralResponse<ViewPharmacyListResponse>> {
        return await this.getDataWithGeneralResponse('/api/v3/pharmacy/list', request)
    }

    async getPharmacyDetail(pharmacyId: number): Promise<GeneralResponse<ViewPharmacyDetailResponse>> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getDataWithGeneralResponse<ViewPharmacyDetailResponse>(
            `/api/v3/pharmacy/view`,
            {
                id: pharmacyId,
            },
            headers
        )
    }

    async getComplaintHomepage(accessToken: string): Promise<ComplaintHomepageResponse> {
        const headers = {
            platform: 'pwa',
            accessToken,
        }

        return await this.getData(`/api/v2/complaint/homepage`, {}, headers)
    }

    async getComplaintDetail(accessToken: string, complaintNumber: string): Promise<GeneralResponse<ComplaintDetailResponse>> {
        const headers = {
            platform: 'pwa',
            accessToken,
        }

        return await this.getDataWithGeneralResponse(`/api/v2/complaint/view`, { complaintNumber }, headers)
    }

    async upsertComplaint(accessToken: string, complaint: UpsertComplaintRequest): Promise<GeneralResponse<ComplaintUpsertResponse>> {
        const headers = {
            platform: 'pwa',
        } as { [name: string]: string }

        if (accessToken && accessToken !== '') {
            headers.accessToken = accessToken
        }

        const fd = new FormData()
        fd.append('orderNumber', complaint.orderNumber)
        fd.append('shipping', complaint.shipping || '')
        fd.append('complaintType', String(complaint.complaintType))
        fd.append('complaintReason', String(complaint.complaintReason))
        fd.append('complaintNote', complaint.complaintNote === null ? '' : complaint.complaintNote)
        fd.append('productAmounts', JSON.stringify(complaint.productAmounts))
        fd.append('serviceId', complaint.complaintServiceId === null ? '' : String(complaint.complaintServiceId))
        fd.append('address', JSON.stringify(complaint.address))
        fd.append('bankAccount', JSON.stringify(complaint.bankAccount))
        fd.append('date', complaint.date || '')
        fd.append('slot', complaint.slot === null ? '' : complaint.slot)
        complaint.files?.forEach((file) => {
            fd.append('files[]', file)
        })

        return await this.getDataWithGeneralResponse(`/api/v2/complaint/upsert`, fd, headers)
    }

    async getOrdersForComplaint(accessToken: string): Promise<OrdersForComplaintResponse> {
        const headers = {
            platform: 'pwa',
            accessToken,
        }

        return await this.getData(`/api/v2/complaint/orders`, {}, headers)
    }

    async getComplaintSettings(): Promise<ComplaintSettingsResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(`/api/v2/complaint/settings`, {}, headers)
    }

    async getProductDetailDataAccess(id, lat?, lng?, radius?, feedbackHash?): Promise<ViewProductResponse> {
        return await this.getData(`/api/v2/product/view`, {
            id,
            uid: '',
            lat,
            lng,
            radius: radius * 1000,
            skipSaleAreas: true,
            skipSimilarProducts: true,
            skipDeliveries: true,
            showExpresData: true,
            feedbackHash,
        })
    }

    async upsertProductBasket(uid, products, accessToken, shouldAddCustomAddonSaleCarousels: boolean) {
        const headers = {
            Accept: `application/vnd.pilulka.basket.lite+json;version=1.0`,
            platform: 'pwa',
            accessToken: '',
        }

        if (accessToken) {
            headers.accessToken = accessToken
        }

        return await this.getData(
            `/api/v2/basket/product/upsert`,
            {
                uid,
                products,
                shouldAddCustomAddonSaleCarousels,
            },
            headers
        )
    }

    async generateBasketUid(accessToken: string): Promise<string> {
        const headers = { platform: 'pwa', accessToken: '' }

        if (accessToken) {
            headers.accessToken = accessToken
        }

        const response = await this.getData(`/api/v2/basket/uid`, headers)

        return response.uid
    }

    async getProductDetailData(id, lat?, lng?, radius?) {
        const headers = {}
        return await this.getData(
            `/api/v2/product/view`,
            {
                id,
                lat,
                lng,
                radius: radius * 1000,
            },
            headers
        )
    }

    async getPharmacyProductsAvailability(id, name?): Promise<ViewPharmacyProductsAvailabilityResponse> {
        return await this.getData(`/api/v2/pharmacy/product/view`, {
            productId: id,
            pharmacyName: name,
        })
    }

    async getServiceOfficeDetailData(id, lat, lng, radius) {
        return await this.getData(`/api/v2/service-office/view`, {
            id,
            lat,
            lng,
            radius: radius * 1000,
        })
    }

    async getProductReviewlist(id): Promise<Review[]> {
        return await this.getData(`/api/v2/review-club/list`, {
            productId: id,
        })
    }

    async getProductReviewDetail(id): Promise<Review> {
        return await this.getData(`/api/v2/review-club/view`, {
            reviewId: id,
        })
    }

    async getBasketProductCarousels(uid: string, variant: string): Promise<GeneralResponse<BasketProductCarouselsResponse>> {
        return await this.getDataWithGeneralResponse(
            `/api/v2/basket/product-carousels`,
            {
                uid,
                variant,
            },
            {
                Accept: `application/vnd.pilulka.basket.carousels+json;version=1.0`,
            }
        )
    }

    async getBasket(uid: string): Promise<GeneralResponse<ViewBasketResponse>> {
        return await this.getDataWithGeneralResponse(
            `/api/v2/basket`,
            {
                uid,
            },
            {
                Accept: `application/vnd.pilulka.basket+json;version=1.0`,
            }
        )
    }

    async addBasketVoucher(uid: string, voucherCode: string): Promise<AddBasketVoucherSuccessResponse | AddBasketVoucherErrorResponse> {
        const response: GeneralResponse<AddBasketVoucherSuccessResponse | AddBasketVoucherErrorResponse> = await $fetch(
            this.constructUrl(`/api/v2/basket/voucher/insert`),
            {
                method: 'POST',
                headers: {},
                body: {
                    uid,
                    voucherCode,
                },
            }
        )

        return response.response
    }

    async deleteBasketVoucher(uid: string, voucherCode: string): Promise<void> {
        await this.getData(`/api/v2/basket/voucher/delete`, {
            uid,
            voucherCode,
        })
    }

    async useBasketCredits(uid: string, useCredits: boolean): Promise<void> {
        return await this.getData(`/api/v2/basket/credits/use`, {
            uid,
            useCredits,
        })
    }

    async getVoucher({ id, lat, lng, accessToken, voucherHash }) {
        const headers = voucherHash ? {} : { accessToken }

        return await this.getData(`/api/v2/service-voucher/view`, { id, lat, lng, voucherHash }, headers)
    }

    async getVouchers({ accessToken }) {
        return await this.getData(`/api/v2/service-voucher/list`, {}, { accessToken })
    }

    async getProductsByIds(ids, preserveIdsOrder) {
        const body = { ids, preserveIdsOrder }

        return await this.getData(`/api/v2/product/_by_ids`, body)
    }

    async getProductRatingByIds(ids: number[]): Promise<ProductRatingResponse> {
        const productIds = ids
        return await this.getData(`/api/v2/product/rating`, { productIds })
    }

    async addFavorite(id: number, { accessToken }): Promise<FavoriteInsertOrDeleteResponse> {
        return await this.getData('/api/v2/favorite/insert', { id: [id] }, { accessToken })
    }

    async removeFavorite(id: number, { accessToken }): Promise<FavoriteInsertOrDeleteResponse> {
        return await this.getData('/api/v2/favorite/delete', { id: [id] }, { accessToken })
    }

    async getComments({ objectId, type, offset, limit, isVerifiedPurchase }) {
        return await this.getData(`/api/v2/review/list`, {
            objectId,
            type,
            from: offset,
            size: limit,
            isVerifiedPurchase: isVerifiedPurchase || false,
        })
    }

    async rateComment({ uid, isUseful, accessToken }) {
        return await this.getData('/api/v2/review/isuseful', { uid, isUseful }, { accessToken })
    }

    async getManufacturer({ id, lat, lng, radius }) {
        return await this.getData(`/api/v2/service-manufacturer/view`, { id, lat, lng, radius: radius * 1000 })
    }

    async addComment({ accessToken, objectId, name, email, text, rating, questionRatings, pros, cons, type, feedbackHash, uid }) {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData(
            `/api/v2/review/upsert`,
            {
                uid,
                objectId,
                type,
                name,
                email,
                text,
                rating,
                questionRatings,
                pros,
                cons,
                feedbackHash,
            },
            headers
        )
    }

    async getReviewUserByFeedbackHash({ feedbackHash }) {
        return await this.getData(`/api/v2/review/view-user`, {
            feedbackHash,
        })
    }

    async rateReviewClub({ reviewId, type, accessToken }) {
        return await this.getData('/api/v2/review-club/rate', { reviewId, type }, { accessToken })
    }

    async sendFeedback({ accessToken, rating, uid, feedbackHash, objectId, type, text }) {
        const headers: any = {}
        if (typeof accessToken === 'string') {
            headers.accessToken = accessToken
        }
        return await this.getData(
            `/api/v3/feedback/save`,
            {
                rating,
                uid,
                feedbackHash,
                objectId,
                type,
                text,
            },
            headers
        )
    }

    async sendFormRecommend({ headers, fullName, email, message }) {
        return await this.getData(
            `/api/v2/suggest-missing-product/send`,
            {
                fullName,
                email,
                message,
            },
            headers
        )
    }

    async getSelectedDiscussionsList(productId: Number, categoryId: Number, { accessToken }): Promise<SelectedDiscussionList[]> {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/list', { productId, categoryId }, headers)
    }

    saveCjEventId(query, accessToken) {
        const { cjevent } = query

        if (!cjevent) {
            return
        }

        return $fetch(this.constructUrl(`/backend/save-event-id/${cjevent}`), {
            headers: { accessToken },
        })
    }

    async validateAndRegisterUser(email: string, agreement: boolean, newsletterDisagree: boolean): Promise<PopUpRegistrationResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/pop-up/registration',
            {
                email,
                agreement,
                newsletterDisagree,
            },
            headers
        )
    }

    validatePostCode(postCode: string): Promise<any> {
        return $fetch(this.constructUrl(`/backend/pilulka-car/${postCode}`))
    }

    async getSlots(postCode: string, delay: number): Promise<SlotsResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/complaint/pilulka-car-slots',
            {
                postCode,
                delayInDays: delay,
            },
            headers
        )
    }

    async getSelectedDiscussionsReport(productId, categoryId) {
        const requestData = {
            productId,
            categoryId,
        }

        return await this.getData('/api/v2/discussion/report', requestData)
    }

    async discussionReport(requestBody: ReportRequestBody) {
        return await this.getData(`/api/v2/discussion/report`, {
            requestBody,
        })
    }

    async discussionUpsertWatchdog(productId: number, notificationFrequency: string, { accessToken }) {
        const headers = accessToken ? { accessToken } : {}
        const requestData = { productId, notificationFrequency }

        return await this.getData('/api/v2/discussion/upsert-watchdog', requestData, headers)
    }

    async discussionUpsert(requestBody, { accessToken }) {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/upsert', requestBody, headers)
    }

    async discussionGetWatchdog(productId: number, { accessToken }): Promise<DiscussionGetWatchdog[]> {
        const headers = accessToken ? { accessToken } : {}
        const requestData = { productId }

        return await this.getData('/api/v2/discussion/get-watchdog', requestData, headers)
    }

    async discussionLikePost(postId: number, { accessToken }): Promise<LikePost> {
        const requestData = { postId }
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/like', requestData, headers)
    }

    async discussionDislikePost(postId: number, { accessToken }): Promise<DisLikePost> {
        const requestData = {
            postId,
        }
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/discussion/dislike', requestData, headers)
    }

    async discussionDeleteWatchdog(id) {
        const requestData = {
            id,
        }
        return await this.getData('/api/v2/discussion/delete-watchdog', requestData)
    }

    async getCategoryProductListBanners(id) {
        const requestData = {
            id,
        }

        return await this.getData('/api/v2/category/product-list-banners', requestData)
    }

    async getStaticPage(url) {
        const {
            headInfo,
            params: { slug },
        } = await this.getRouteProperties(url)

        const response = await this.getStaticPageWidgets(slug, url)

        return { ...response, headInfo }
    }

    logConsentChange(cookieName, status, clientId = null) {
        this.getData('/api/v2/cookie/log/upsert', {
            cookieName,
            clientId,
            isAllowed: status === 'granted',
        })
    }

    async getSearchData(searchTerm) {
        const url = this.constructUrl(`/backend/hledej-ajax?q=${searchTerm}`)
        const response = await fetch(url)

        if ((await response.json()).status === 200) {
            return (await response.json()).data
        } else {
            throw new Error('Homepage data could not be loaded.')
        }
    }

    getStaticPageWidgets(slug, url): Promise<ViewStaticContentResponse> {
        return this.getData('/api/v3/content/static/view', { slug, url })
    }

    getRouteProperties(url) {
        return this.getData('/api/v2/pwa/router', { url })
    }

    getMenu() {
        return this.getData('/api/v3/menu/view', { maxDepth: 3 })
    }

    getCommentAggregate(id: Number, type: string): Promise<CommentAggregateResponse> {
        return this.getData('/api/v2/comment/aggregate', { id, type })
    }

    getArticleList(currentUrl: string, page: number, filter: Object, sort: number): Promise<GeneralResponse<ViewArticleListResponse>> {
        return this.getDataWithGeneralResponse('/api/v2/article/list', { page, currentUrl, filter, sort })
    }

    getPwaMenu() {
        const headers = {
            Accept: 'application/json;version=1.0',
        }

        return this.getData('/api/v2/pwa/menu', {}, headers)
    }

    async getPWAHomepageData({ accessToken, response }) {
        const headers = accessToken ? { accessToken } : {}

        return await this.getData('/api/v2/pwa/homepage', { response }, headers)
    }

    async login(email: string, password: string) {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/login',
            {
                email,
                password,
            },
            headers
        )
    }

    async findComplaintOrder(orderNumber: string, lastName: string): Promise<ComplaintFindOrderResponse> {
        const headers = {
            platform: 'pwa',
        }

        return await this.getData(
            '/api/v2/complaint/find-order',
            {
                orderNumber,
                lastName,
            },
            headers
        )
    }

    async getCsrProducts() {
        return await this.getData('/api/v2/product/csr/list')
    }

    async getAddonSaleBundles(
        productId: number,
        strategy: AddonSaleBundleStrategy,
        excludeProducts: number[] = []
    ): Promise<ProductAddonSaleBundleResponse> {
        return await this.getData(`/api/v2/product/addon-sale-bundles`, {
            id: productId,
            strategy,
            excludeProducts,
        })
    }

    async getAddonSaleArea(addonSaleAreaId: number, excludeProducts: number[] = []): Promise<ProductAddonSaleAreasResponsePart> {
        return await this.getData(`/api/v2/product/addon-sale-area`, {
            id: addonSaleAreaId,
            excludeProducts,
        })
    }

    async getGiftEventProducts(uid: string, giftEventIds: number[] = []): Promise<GiftEventProductsResponse> {
        return await this.getData(`/api/v2/product/gift-events/active-products`, {
            uid,
            giftEventIds,
        })
    }

    async getCustomHomepageAddonSaleCarousels() {
        const headers = {
            platform: 'pwa',
        }

        const response = await this.getData('/api/v2/home/view-partial', {
            partialName: 'customHomepageAddonSaleCarousels',
            headers,
        })

        return response.customHomepageAddonSaleCarousels
    }

    async getData(url, inputData = {}, headers = {}) {
        // console.log(`!!! API call ${this.constructUrl(url)} ${JSON.stringify(inputData)}`)
        let response = null
        try {
            response = await $fetch(this.constructUrl(url), {
                method: 'POST',
                headers,
                body: inputData,
            })
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                const errorMessage = `Data could not be loaded from url ${url}. InputData: ${JSON.stringify(inputData)}. ${e}. Response: ${
                    e.response
                }`
                // useSentry()?.sentryCaptureException?.(errorMessage)

                throw new Error(errorMessage)
            }

            throw e
        }

        const data: any = response
        const result = data.result
        const responseData = data.response

        if (result) {
            return responseData
        } else if (data.code === 300 || data.code === 307) {
            return data
        } else {
            const Exception = data.code === 404 ? NotFoundError : Error

            throw new Exception(
                `Data could not be loaded from url ${url}. InputData: ${JSON.stringify(inputData)}. Message ${data.message}`
            )
        }
    }

    async getDataWithGeneralResponse<T>(url, inputData = {}, headers = {}): Promise<GeneralResponse<T>> {
        try {
            return await $fetch(this.constructUrl(url), {
                method: 'POST',
                headers,
                body: inputData,
            })
        } catch (e: unknown) {
            if (e instanceof FetchError) {
                return e.response?._data
            }

            throw e
        }
    }
}
