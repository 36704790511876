import Repository from '~/api/repository'

// declare module '#app' {
//     interface NuxtApp {
//         $repository: Repository
//     }
// }
//
// declare module '@nuxt/types' {
//     interface Context {
//         $repository: Repository
//     }
// }

export default defineNuxtPlugin(() => {
    const config = useRuntimeConfig()
    return {
        provide: {
            repository: new Repository(config),
        },
    }
})
