<template>
    <div>
        <NuxtLayout v-if="error.statusCode === 404" name="default">
            <div class="container p-0">
                <div class="page404">
                    <div class="page404__col1">
                        <h1 class="page404__title">{{ $t('page.404.title') }}</h1>
                        <p class="page404__perex">{{ $t('page.404.perex') }}</p>
                        <p class="page404__button page404__button--top">
                            <a href="/" class="btn btn-primary px-2 px-md-5 fs-14 fs-md-14">{{ $t('page.404.goToHp') }}</a>
                        </p>
                    </div>
                    <div class="page404__col2">
                        <div class="page404__images"></div>
                        <p class="page404__button page404__button--bottom">
                            <a href="/" class="btn btn-primary px-2 px-md-5 fs-14 fs-md-14">{{ $t('page.404.goToHp') }}</a>
                        </p>
                    </div>

                    <Category404 class="page404__col3" />
                </div>

                <PilulkaCarGame />
            </div>
        </NuxtLayout>
        <div v-else class="page404 justify-content-center text-center">
            <div>
                <h1 class="page404__title">{{ $t('page.500.title') }}</h1>
                <a href="/" class="btn btn-primary px-2 px-md-5 fs-14 fs-md-14">{{ $t('page.500.goToHp') }}</a>
                <p style="display: block">{{ error.message }} {{ error }}</p>

                <PilulkaCarGame />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import DataLayer from './extensions/DataLayer'
import type GtmPageData from '~/models/DataLayer/GtmPageData'
import Category404 from '~/components/layout/page/category404.vue'

export default defineNuxtComponent({
    components: { Category404 },
    props: {
        error: {
            type: Object as PropType<{
                url: string
                statusCode: number
                statusMessage: string
                message: string
                description: string
                data: any
            }>,
            required: true,
        },
    },

    scrollToTop: true,

    mounted(): void {
        if (this.error.statusCode !== 404) {
            this.$sentryCaptureException?.(this.error.message)
        }
        const page = {
            type: `error ${this.error.statusCode}`,
        } as GtmPageData

        DataLayer.pushPageViewToDataLayer(page)
    },
})
</script>

<style lang="scss" scoped>
/*
Structure:
  .page404
    .page404__col1
      .page404__title
      .page404__perex
    .page404__col2
      .page404__images
    .page404__col3
      .page404__subtitle
      .page404__category
        .page404__item
          .page404__item-img
          .page404__item-name
    .page404__button(.page404__button--top|.page404__button--bottom)
*/
</style>
