import { onLCP, onFCP, onTTFB, LCPMetric } from 'web-vitals'
import { onCLS, onINP } from 'web-vitals/attribution'
import { useBasketStore } from '~/stores/basket'
import { useAbTestStore } from '~/stores/abTest'
import { useLayoutStore } from '~/stores/layout'
import { useAuthStore } from '~/stores/auth'
import getDevice from '~/extensions/getDevice'
import RouteCookie from '~/models/Cookie/RouteCookie'
import { usePerformanceStore } from '~/stores/performace'

function initAbTests() {
    const layoutStore = useLayoutStore()
    const abTestStore = useAbTestStore()
    abTestStore.setAbTestNames(layoutStore.abTests)
}

function initZendeskButton() {
    setTimeout(() => {
        const zendeskButton = document.getElementById('zdbutton')

        zendeskButton?.addEventListener('click', () => {
            zendeskButton.classList.add('d-none')
        })
    }, 2000)
}

function initUidCookie() {
    const basketStore = useBasketStore()
    const uidCookie = useUidCookie()
    if (basketStore.uid) {
        uidCookie.value = basketStore.uid
    }
}

async function initBasket() {
    const basketStore = useBasketStore()
    await Promise.all([basketStore.fetchData(true)])
}

async function initAuth() {
    const authStore = useAuthStore()
    await authStore.initFromCookies()
}

// @see https://github.com/GoogleChrome/web-vitals/blob/main/src/lib/getSelector.ts
function getName(node: Node) {
    const name = node.nodeName
    return node.nodeType === 1 ? name.toLowerCase() : name.toUpperCase().replace(/^#/, '')
}

function getSelector(node: Node | null | undefined, maxLen?: number) {
    let sel = ''

    try {
        while (node && node.nodeType !== 9) {
            const el: Element = node as Element
            const part = el.id
                ? '#' + el.id
                : getName(el) +
                  (el.classList && el.classList.value && el.classList.value.trim() && el.classList.value.trim().length
                      ? '.' + el.classList.value.trim().replace(/\s+/g, '.')
                      : '')
            if (sel.length + part.length > (maxLen || 100) - 1) return sel || part
            sel = sel ? part + '>' + sel : part
            if (el.id) break
            node = el.parentNode
        }
    } catch (err) {
        // Do nothing...
    }

    return sel
}

function initCoreWebVitals() {
    let lastTTFB: number | null = null
    let lastFCP: number | null = null
    let lcpInpData: LCPMetric | null = null

    let lastInteractedElement: string | null = null
    document.addEventListener('click', (e) => {
        lastInteractedElement = getSelector((e.target || e.srcElement) as Node)
    })
    document.addEventListener('pointerup', (e) => {
        lastInteractedElement = getSelector((e.target || e.srcElement) as Node)
    })
    document.addEventListener('pointerdown', (e) => {
        lastInteractedElement = getSelector((e.target || e.srcElement) as Node)
    })

    const cwvCallback = (inputData: any) => {
        const route = useRoute()
        const routeCookie = useCookie<RouteCookie>('route')
        const config = useRuntimeConfig()
        const nuxtApp = useNuxtApp()
        let timings = {} as { [name: string]: number | null }
        if (inputData.name === 'LCP') {
            timings = performanceStore.list
            timings.ttfb = lastTTFB
            timings.fcp = lastFCP
            timings.lcp = inputData.value

            inputData = {
                attribution: {
                    eventTarget: JSON.stringify(timings),
                },
                ...inputData,
            }
        }
        if (inputData.name === 'INP' && !inputData?.attribution?.eventTarget) {
            inputData.attribution.eventTarget = `empty - ${lastInteractedElement}`
        }
        inputData = {
            path: route.fullPath,
            urlType: routeCookie.value.name,
            isServerRender: process.client && nuxtApp.isHydrating && nuxtApp.payload.serverRendered,
            lastTTFB,
            device: getDevice(),
            isPwa: true,
            timings: JSON.parse(JSON.stringify(timings)),
            ...inputData,
        }

        const url = `${config.public.apiUrl}/api/v2/log/web-vitals`
        if (navigator.sendBeacon) {
            navigator.sendBeacon(url, JSON.stringify(inputData))

            return
        }

        $fetch(url, {
            method: 'POST',
            body: inputData,
        })
    }

    onTTFB((inputData) => {
        lastTTFB = inputData.value
        if (lcpInpData !== null && lastFCP !== null) {
            cwvCallback(lcpInpData)
        }
    })

    onFCP((inputData) => {
        lastFCP = inputData.value
        if (lcpInpData !== null && lastTTFB !== null) {
            cwvCallback(lcpInpData)
        }
    })

    const performanceStore = usePerformanceStore()
    onLCP((inputData) => {
        const newInputData = {
            ...inputData,
        }

        lcpInpData = newInputData
        if (lastTTFB !== null && lastFCP !== null) {
            cwvCallback(newInputData)
        }
    })

    onINP(
        (inputData) => {
            cwvCallback(inputData)
        }
        // { reportAllChanges: true }
    )
    onCLS(
        (inputData) => {
            cwvCallback(inputData)
        }
        // { reportAllChanges: true }
    )
}

export default defineNuxtPlugin(async () => {
    await initAuth()
    await initBasket()
    initUidCookie()
    initAbTests()
    initZendeskButton()
    initCoreWebVitals()
    await initBasket()
})
