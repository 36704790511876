export enum GtmEcommerceEventTypeEnum {
    AddToCart = 'add_to_cart',
    RemoveFromCart = 'remove_from_cart',
    ViewProductDetail = 'view_item',
    ViewProductList = 'view_item_list',
    AddToFavorites = 'add_to_favorites',
    ClickToProductDetail = 'select_item',
    ViewProductSubstitutionAutoNonAggressive = 'view_product_substitution_auto_non_aggressive',
    ViewProductSubstitutionManualNonAggressive = 'view_product_substitution_manual_non_aggressive',
    ViewProductSubstitutionAutoAggressive = 'view_product_substitution_auto_aggressive',
    ViewProductSubstitutionManualAggressive = 'view_product_substitution_manual_aggressive',
    ViewCart = 'view_cart',
}
