import { default as articleList0G8CZp7meCMeta } from "/usr/src/app/pages/articleList.vue?macro=true";
import { default as cartStep1x1SRwC2ZBnMeta } from "/usr/src/app/pages/cart/cartStep1.vue?macro=true";
import { default as _6OYIWfxvddMeta } from "/usr/src/app/pages/category/_.vue?macro=true";
import { default as createComplaint0gOu6Qucp1Meta } from "/usr/src/app/pages/complaint/createComplaint.vue?macro=true";
import { default as homepagevIttuXxG96Meta } from "/usr/src/app/pages/complaint/homepage.vue?macro=true";
import { default as detail5cSb7PgsohMeta } from "/usr/src/app/pages/counselling/detail.vue?macro=true";
import { default as homepagezr6P8GHRmOMeta } from "/usr/src/app/pages/counselling/homepage.vue?macro=true";
import { default as list2KwbWktmZeMeta } from "/usr/src/app/pages/counselling/list.vue?macro=true";
import { default as detaildYd9xgitIDMeta } from "/usr/src/app/pages/detail.vue?macro=true";
import { default as emptyTwqVDykXHeMeta } from "/usr/src/app/pages/empty.vue?macro=true";
import { default as emptyNoLayoutPgD8EqihvtMeta } from "/usr/src/app/pages/emptyNoLayout.vue?macro=true";
import { default as emptyNoLayoutNoGTMzhJFXUIag6Meta } from "/usr/src/app/pages/emptyNoLayoutNoGTM.vue?macro=true";
import { default as formRecommendBdbRFu5N8gMeta } from "/usr/src/app/pages/formRecommend.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as paperAuthordy12kge36WMeta } from "/usr/src/app/pages/paperAuthor.vue?macro=true";
import { default as detailzQOktCWToLMeta } from "/usr/src/app/pages/pharmacy/detail.vue?macro=true";
import { default as list2E14W0I0hUMeta } from "/usr/src/app/pages/pharmacy/list.vue?macro=true";
import { default as redirectPermanentozF5VWMgBiMeta } from "/usr/src/app/pages/redirectPermanent.vue?macro=true";
import { default as redirectToLegacyA4nKKagwhaMeta } from "/usr/src/app/pages/redirectToLegacy.vue?macro=true";
import { default as _C9WK7nOgIUMeta } from "/usr/src/app/pages/static/_.vue?macro=true";
import { default as staticSamplexAEkyhJ5sXMeta } from "/usr/src/app/pages/staticSample.vue?macro=true";
import { default as terminationQmxmk1JKXSMeta } from "/usr/src/app/pages/termination.vue?macro=true";
import { default as userYgr9W7cUghMeta } from "/usr/src/app/pages/user.vue?macro=true";
import { default as userVoucherTestPZAgXrAvddMeta } from "/usr/src/app/pages/userVoucherTest.vue?macro=true";
export default [
  {
    name: articleList0G8CZp7meCMeta?.name ?? "articleList",
    path: articleList0G8CZp7meCMeta?.path ?? "/articleList",
    meta: articleList0G8CZp7meCMeta || {},
    alias: articleList0G8CZp7meCMeta?.alias || [],
    redirect: articleList0G8CZp7meCMeta?.redirect,
    component: () => import("/usr/src/app/pages/articleList.vue").then(m => m.default || m)
  },
  {
    name: cartStep1x1SRwC2ZBnMeta?.name ?? "cart-cartStep1",
    path: cartStep1x1SRwC2ZBnMeta?.path ?? "/cart/cartStep1",
    meta: cartStep1x1SRwC2ZBnMeta || {},
    alias: cartStep1x1SRwC2ZBnMeta?.alias || [],
    redirect: cartStep1x1SRwC2ZBnMeta?.redirect,
    component: () => import("/usr/src/app/pages/cart/cartStep1.vue").then(m => m.default || m)
  },
  {
    name: _6OYIWfxvddMeta?.name ?? "category-_",
    path: _6OYIWfxvddMeta?.path ?? "/category/_",
    meta: _6OYIWfxvddMeta || {},
    alias: _6OYIWfxvddMeta?.alias || [],
    redirect: _6OYIWfxvddMeta?.redirect,
    component: () => import("/usr/src/app/pages/category/_.vue").then(m => m.default || m)
  },
  {
    name: createComplaint0gOu6Qucp1Meta?.name ?? "complaint-createComplaint",
    path: createComplaint0gOu6Qucp1Meta?.path ?? "/complaint/createComplaint",
    meta: createComplaint0gOu6Qucp1Meta || {},
    alias: createComplaint0gOu6Qucp1Meta?.alias || [],
    redirect: createComplaint0gOu6Qucp1Meta?.redirect,
    component: () => import("/usr/src/app/pages/complaint/createComplaint.vue").then(m => m.default || m)
  },
  {
    name: homepagevIttuXxG96Meta?.name ?? "complaint-homepage",
    path: homepagevIttuXxG96Meta?.path ?? "/complaint/homepage",
    meta: homepagevIttuXxG96Meta || {},
    alias: homepagevIttuXxG96Meta?.alias || [],
    redirect: homepagevIttuXxG96Meta?.redirect,
    component: () => import("/usr/src/app/pages/complaint/homepage.vue").then(m => m.default || m)
  },
  {
    name: detail5cSb7PgsohMeta?.name ?? "counselling-detail",
    path: detail5cSb7PgsohMeta?.path ?? "/counselling/detail",
    meta: detail5cSb7PgsohMeta || {},
    alias: detail5cSb7PgsohMeta?.alias || [],
    redirect: detail5cSb7PgsohMeta?.redirect,
    component: () => import("/usr/src/app/pages/counselling/detail.vue").then(m => m.default || m)
  },
  {
    name: homepagezr6P8GHRmOMeta?.name ?? "counselling-homepage",
    path: homepagezr6P8GHRmOMeta?.path ?? "/counselling/homepage",
    meta: homepagezr6P8GHRmOMeta || {},
    alias: homepagezr6P8GHRmOMeta?.alias || [],
    redirect: homepagezr6P8GHRmOMeta?.redirect,
    component: () => import("/usr/src/app/pages/counselling/homepage.vue").then(m => m.default || m)
  },
  {
    name: list2KwbWktmZeMeta?.name ?? "counselling-list",
    path: list2KwbWktmZeMeta?.path ?? "/counselling/list",
    meta: list2KwbWktmZeMeta || {},
    alias: list2KwbWktmZeMeta?.alias || [],
    redirect: list2KwbWktmZeMeta?.redirect,
    component: () => import("/usr/src/app/pages/counselling/list.vue").then(m => m.default || m)
  },
  {
    name: detaildYd9xgitIDMeta?.name ?? "detail",
    path: detaildYd9xgitIDMeta?.path ?? "/detail",
    meta: detaildYd9xgitIDMeta || {},
    alias: detaildYd9xgitIDMeta?.alias || [],
    redirect: detaildYd9xgitIDMeta?.redirect,
    component: () => import("/usr/src/app/pages/detail.vue").then(m => m.default || m)
  },
  {
    name: emptyTwqVDykXHeMeta?.name ?? "empty",
    path: emptyTwqVDykXHeMeta?.path ?? "/empty",
    meta: emptyTwqVDykXHeMeta || {},
    alias: emptyTwqVDykXHeMeta?.alias || [],
    redirect: emptyTwqVDykXHeMeta?.redirect,
    component: () => import("/usr/src/app/pages/empty.vue").then(m => m.default || m)
  },
  {
    name: emptyNoLayoutPgD8EqihvtMeta?.name ?? "emptyNoLayout",
    path: emptyNoLayoutPgD8EqihvtMeta?.path ?? "/emptyNoLayout",
    meta: emptyNoLayoutPgD8EqihvtMeta || {},
    alias: emptyNoLayoutPgD8EqihvtMeta?.alias || [],
    redirect: emptyNoLayoutPgD8EqihvtMeta?.redirect,
    component: () => import("/usr/src/app/pages/emptyNoLayout.vue").then(m => m.default || m)
  },
  {
    name: emptyNoLayoutNoGTMzhJFXUIag6Meta?.name ?? "emptyNoLayoutNoGTM",
    path: emptyNoLayoutNoGTMzhJFXUIag6Meta?.path ?? "/emptyNoLayoutNoGTM",
    meta: emptyNoLayoutNoGTMzhJFXUIag6Meta || {},
    alias: emptyNoLayoutNoGTMzhJFXUIag6Meta?.alias || [],
    redirect: emptyNoLayoutNoGTMzhJFXUIag6Meta?.redirect,
    component: () => import("/usr/src/app/pages/emptyNoLayoutNoGTM.vue").then(m => m.default || m)
  },
  {
    name: formRecommendBdbRFu5N8gMeta?.name ?? "formRecommend",
    path: formRecommendBdbRFu5N8gMeta?.path ?? "/formRecommend",
    meta: formRecommendBdbRFu5N8gMeta || {},
    alias: formRecommendBdbRFu5N8gMeta?.alias || [],
    redirect: formRecommendBdbRFu5N8gMeta?.redirect,
    component: () => import("/usr/src/app/pages/formRecommend.vue").then(m => m.default || m)
  },
  {
    name: indexgw2ikJsyhcMeta?.name ?? "index",
    path: indexgw2ikJsyhcMeta?.path ?? "/",
    meta: indexgw2ikJsyhcMeta || {},
    alias: indexgw2ikJsyhcMeta?.alias || [],
    redirect: indexgw2ikJsyhcMeta?.redirect,
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: paperAuthordy12kge36WMeta?.name ?? "paperAuthor",
    path: paperAuthordy12kge36WMeta?.path ?? "/paperAuthor",
    meta: paperAuthordy12kge36WMeta || {},
    alias: paperAuthordy12kge36WMeta?.alias || [],
    redirect: paperAuthordy12kge36WMeta?.redirect,
    component: () => import("/usr/src/app/pages/paperAuthor.vue").then(m => m.default || m)
  },
  {
    name: detailzQOktCWToLMeta?.name ?? "pharmacy-detail",
    path: detailzQOktCWToLMeta?.path ?? "/pharmacy/detail",
    meta: detailzQOktCWToLMeta || {},
    alias: detailzQOktCWToLMeta?.alias || [],
    redirect: detailzQOktCWToLMeta?.redirect,
    component: () => import("/usr/src/app/pages/pharmacy/detail.vue").then(m => m.default || m)
  },
  {
    name: list2E14W0I0hUMeta?.name ?? "pharmacy-list",
    path: list2E14W0I0hUMeta?.path ?? "/pharmacy/list",
    meta: list2E14W0I0hUMeta || {},
    alias: list2E14W0I0hUMeta?.alias || [],
    redirect: list2E14W0I0hUMeta?.redirect,
    component: () => import("/usr/src/app/pages/pharmacy/list.vue").then(m => m.default || m)
  },
  {
    name: redirectPermanentozF5VWMgBiMeta?.name ?? "redirectPermanent",
    path: redirectPermanentozF5VWMgBiMeta?.path ?? "/redirectPermanent",
    meta: redirectPermanentozF5VWMgBiMeta || {},
    alias: redirectPermanentozF5VWMgBiMeta?.alias || [],
    redirect: redirectPermanentozF5VWMgBiMeta?.redirect,
    component: () => import("/usr/src/app/pages/redirectPermanent.vue").then(m => m.default || m)
  },
  {
    name: redirectToLegacyA4nKKagwhaMeta?.name ?? "redirectToLegacy",
    path: redirectToLegacyA4nKKagwhaMeta?.path ?? "/redirectToLegacy",
    meta: redirectToLegacyA4nKKagwhaMeta || {},
    alias: redirectToLegacyA4nKKagwhaMeta?.alias || [],
    redirect: redirectToLegacyA4nKKagwhaMeta?.redirect,
    component: () => import("/usr/src/app/pages/redirectToLegacy.vue").then(m => m.default || m)
  },
  {
    name: _C9WK7nOgIUMeta?.name ?? "static-_",
    path: _C9WK7nOgIUMeta?.path ?? "/static/_",
    meta: _C9WK7nOgIUMeta || {},
    alias: _C9WK7nOgIUMeta?.alias || [],
    redirect: _C9WK7nOgIUMeta?.redirect,
    component: () => import("/usr/src/app/pages/static/_.vue").then(m => m.default || m)
  },
  {
    name: staticSamplexAEkyhJ5sXMeta?.name ?? "staticSample",
    path: staticSamplexAEkyhJ5sXMeta?.path ?? "/staticSample",
    meta: staticSamplexAEkyhJ5sXMeta || {},
    alias: staticSamplexAEkyhJ5sXMeta?.alias || [],
    redirect: staticSamplexAEkyhJ5sXMeta?.redirect,
    component: () => import("/usr/src/app/pages/staticSample.vue").then(m => m.default || m)
  },
  {
    name: terminationQmxmk1JKXSMeta?.name ?? "termination",
    path: terminationQmxmk1JKXSMeta?.path ?? "/termination",
    meta: terminationQmxmk1JKXSMeta || {},
    alias: terminationQmxmk1JKXSMeta?.alias || [],
    redirect: terminationQmxmk1JKXSMeta?.redirect,
    component: () => import("/usr/src/app/pages/termination.vue").then(m => m.default || m)
  },
  {
    name: userYgr9W7cUghMeta?.name ?? "user",
    path: userYgr9W7cUghMeta?.path ?? "/user",
    meta: userYgr9W7cUghMeta || {},
    alias: userYgr9W7cUghMeta?.alias || [],
    redirect: userYgr9W7cUghMeta?.redirect,
    component: () => import("/usr/src/app/pages/user.vue").then(m => m.default || m)
  },
  {
    name: userVoucherTestPZAgXrAvddMeta?.name ?? "userVoucherTest",
    path: userVoucherTestPZAgXrAvddMeta?.path ?? "/userVoucherTest",
    meta: userVoucherTestPZAgXrAvddMeta || {},
    alias: userVoucherTestPZAgXrAvddMeta?.alias || [],
    redirect: userVoucherTestPZAgXrAvddMeta?.redirect,
    component: () => import("/usr/src/app/pages/userVoucherTest.vue").then(m => m.default || m)
  }
]